



























import Vue from 'vue'
import SearchCount from '@/views/stylist/reports/miniGraph/SearchCount.vue'
import ClicksCount from '@/views/stylist/reports/miniGraph/ClicksCount.vue'
import ClickThroughtRate from '@/views/stylist/reports/miniGraph/ClickThroughtRate.vue'
import OrdersCount from '@/views/stylist/reports/miniGraph/OrdersCount.vue'
import SalesTotalCount from '@/views/stylist/reports/miniGraph/SalesTotalCount.vue'
import ConversionPercent from '@/views/stylist/reports/miniGraph/ConversionPercent.vue'

export default Vue.extend({
  name: 'AllReports',
  components: {
    ConversionPercent,
    SalesTotalCount,
    OrdersCount,
    ClickThroughtRate,
    ClicksCount,
    SearchCount,
  },

})
