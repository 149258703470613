


















import Vue from 'vue'
import store from '@/store'
import StylistReports from '@/store/stylist/reports/stylistReports'
import SearchMiniGraphV3 from '@/views/components/search/SearchMiniGraphV3.vue'

export default Vue.extend({
  components: {
    SearchMiniGraphV3,
  },
  computed: {
    chartData() {
      return StylistReports.state.clickThroughRate.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    end() {
      return (store.state as any).search.dateRange.end
    },
    dataLoading() {
      return StylistReports.state.generalPerformanceIsLoading
    },
    chartSummaryData() {
      return StylistReports.state.clickThroughRate.summaryData
    },
  },
  watch: {
    start() {
      this.refreshTimeData();
    },
    end() {
      this.refreshTimeData();
    },
  },
  mounted() {
    StylistReports.dispatch('getGeneralPerformance')
  },
  methods: {
    refreshTimeData(){
      StylistReports.commit('setTimeFrame', (store.state as any).search.dateRange)
      StylistReports.dispatch('getGeneralPerformance')
    },
  },
})
