import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment/moment'
import {
  CountForData, GeneralPerformanceData,
  ReportTimeFrame,
  StylistApiRequestHelper,
} from '@/store/stylist/stylistApiRequestHelper'
import Notifications from '@/store/notifications/notifications'
import { AxiosResponse } from 'axios'

Vue.use(Vuex)

interface SearchesCountPerDate {
  timeFrame: ReportTimeFrame;
  searchPerDay: {
    data: CountForData[],
    summaryData: number,
  }
  clicksPerDay: {
    data: CountForData[],
    summaryData: number,
  };
  clickThroughRate: {
    data: CountForData[],
    summaryData: number,
  };
  transactionCount: {
    data: CountForData[],
    summaryData: number,
  };
  salesAmount: {
    data: CountForData[],
    summaryData: number,
  };
  conversionPercent: {
    data: CountForData[],
    summaryData: number,
  };
  generalPerformanceIsLoading: boolean;
}

const getInitialState = (): SearchesCountPerDate => ({
  timeFrame: {
    start: moment().subtract(1, 'week').format('YYYY-MM-DD 00:00:00'),
    end: moment().format('YYYY-MM-DD 23:59:59'),
  },
  searchPerDay: { data: [], summaryData: 0 },
  clicksPerDay: { data: [], summaryData: 0 },
  clickThroughRate: { data: [], summaryData: 0 },
  transactionCount: { data: [], summaryData: 0 },
  salesAmount: { data: [], summaryData: 0 },
  conversionPercent: { data: [], summaryData: 0 },
  generalPerformanceIsLoading: false,
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setTimeFrame(state: any, timeFrame: ReportTimeFrame) {
      state.timeFrame.start = moment(timeFrame.start).format('YYYY-MM-DD 00:00:00')
      state.timeFrame.end = moment(timeFrame.end).format('YYYY-MM-DD 23:59:59')
    },
    setGeneralPerformancePerDayData(state: any, data: GeneralPerformanceData[]) {
      state.searchPerDay.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: dayGeneralPerformance.views,
      }))
      state.searchPerDay.summaryData = state.searchPerDay.data.reduce((accumulator: number, objectData: CountForData) => accumulator + objectData.count, 0)

      state.clicksPerDay.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: dayGeneralPerformance.clicks,
      }))
      state.clicksPerDay.summaryData = state.clicksPerDay.data.reduce((accumulator: number, objectData: CountForData) => accumulator + objectData.count, 0)

      state.clickThroughRate.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: dayGeneralPerformance.views === 0 ? 0 : ((dayGeneralPerformance.clicks / dayGeneralPerformance.views) * 100),
      }))
      state.clickThroughRate.summaryData = state.searchPerDay.summaryData === 0 ? 0 : (state.clicksPerDay.summaryData / state.searchPerDay.summaryData) * 100

      state.transactionCount.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: Number(dayGeneralPerformance.salesCount),
      }))
      state.transactionCount.summaryData = state.transactionCount.data.reduce((accumulator: number, objectData: CountForData) => accumulator + objectData.count, 0)

      state.salesAmount.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: Number(dayGeneralPerformance.salesTotal),
      }))
      state.salesAmount.summaryData = state.salesAmount.data.reduce((accumulator: number, objectData: CountForData) => accumulator + objectData.count, 0)

      state.conversionPercent.data = data.map((dayGeneralPerformance: GeneralPerformanceData) => ({
        date: dayGeneralPerformance.periodStart,
        count: Number(dayGeneralPerformance.salesConversion),
      }))
      state.conversionPercent.summaryData = state.clicksPerDay.summaryData === 0 ? 0 : ((state.transactionCount.summaryData / state.clicksPerDay.summaryData) * 100)
    },
    setGeneralPerformanceDataLoading(state: any, loading: boolean): void {
      state.generalPerformanceIsLoading = loading
    },
  },
  actions: {
    getGeneralPerformance(context) {
      if (context.state.generalPerformanceIsLoading) {
        return
      }
      context.commit('setGeneralPerformanceDataLoading', true)
      StylistApiRequestHelper.getSearchesGeneralPerformancePerDay(context.state.timeFrame)
        .then((result: AxiosResponse<Array<GeneralPerformanceData>>) => {
          context.commit('setGeneralPerformanceDataLoading', false)
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setGeneralPerformancePerDayData', result.data)
        })
        .catch(error => {
          context.commit('setGeneralPerformanceDataLoading', false)
          Notifications.commit('addPopupError', error)
        })
    },
  },
})
